"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const colors_1 = require("../../common/colors");
const rootNavigation_1 = require("../../navigation/rootNavigation");
const AppIcons_1 = require("../../component/AppIcons");
const useDevice_1 = require("../../hooks/useDevice");
const useAppQuota_1 = require("../../hooks/useAppQuota");
const user_1 = require("../../slices/user");
const AppText_1 = require("../AppText");
const react_native_1 = require("react-native");
const native_1 = require("@react-navigation/native");
const PENDING_MESSAGE_COUNT_TRIGGER = 50;
const AppQuotaAlert = () => {
    const activeSubscription = (0, react_redux_1.useSelector)((state) => state.subscriptionPlan.subscription);
    const fbUser = (0, react_redux_1.useSelector)((state) => state.getUserOptions.fbUser);
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    const navigation = (0, native_1.useNavigation)();
    const handleMembershipAction = (0, react_1.useCallback)(async () => {
        if (fbUser && fbUser?.uid) {
            if (isWebDevice) {
                (0, rootNavigation_1.navigate)("Pricing");
            }
            else {
                await dispatch((0, user_1.setUserAction)("membership"));
            }
        }
        else {
            if (isWebDevice) {
                navigation.navigate('LoginPage');
            }
            else {
                await dispatch((0, user_1.setUserAction)("signin"));
            }
        }
    }, [fbUser, navigation]);
    const { pendingMessageCount, consumedMessagesPercent } = (0, useAppQuota_1.useAppQuota)();
    const dispatch = (0, react_redux_1.useDispatch)();
    // don't show alert bar if we are not getting quota from API or we have subscriptions
    if (activeSubscription?.identifier)
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    return ((0, jsx_runtime_1.jsx)(native_base_1.Stack, { children: pendingMessageCount <= PENDING_MESSAGE_COUNT_TRIGGER && ((0, jsx_runtime_1.jsxs)(native_base_1.HStack, { justifyContent: "space-between", background: colors_1.Colors.DARK_600, height: isWebDevice && isMobileScreen ? 50 : undefined, py: react_native_1.Platform.OS === 'web' ? 3 : 2, px: 3, mx: react_native_1.Platform.OS === 'web' ? 0 : 3, mb: react_native_1.Platform.OS === 'web' ? 0 : 2, mt: react_native_1.Platform.OS === 'web' ? 0 : 2, borderRadius: 8, children: [(0, jsx_runtime_1.jsxs)(native_base_1.HStack, { alignItems: "center", children: [(0, jsx_runtime_1.jsxs)(native_base_1.HStack, { children: [consumedMessagesPercent < 50 && (0, jsx_runtime_1.jsx)(AppIcons_1.GreenAlertIcon, {}), consumedMessagesPercent === 100 && (0, jsx_runtime_1.jsx)(AppIcons_1.RedAlertIcon, {}), consumedMessagesPercent >= 50 && consumedMessagesPercent < 100 && ((0, jsx_runtime_1.jsx)(AppIcons_1.YellowAlertIcon, {}))] }), (0, jsx_runtime_1.jsx)(native_base_1.HStack, { pl: react_native_1.Platform.OS === 'web' ? 3 : 2, children: (0, jsx_runtime_1.jsxs)(AppText_1.AppText, { color: isWebDevice && isMobileScreen ? 'white' : colors_1.Colors.white, opacity: isWebDevice && isMobileScreen ? 0.8 : undefined, fontSize: react_native_1.Platform.OS === 'web' ? 16 : undefined, children: [pendingMessageCount, " messages left"] }) })] }), (0, jsx_runtime_1.jsx)(native_base_1.HStack, { children: !fbUser ? ((0, jsx_runtime_1.jsx)(native_base_1.Pressable, { onPress: handleMembershipAction, backgroundColor: react_native_1.Platform.OS === 'web' && !isMobileScreen
                            ? colors_1.Colors.PRIMARY_300
                            : undefined, paddingX: !(isWebDevice && isMobileScreen) ? 2 : undefined, paddingY: !(isWebDevice && isMobileScreen) ? 1 : undefined, borderRadius: !(isWebDevice && isMobileScreen) ? 15 : undefined, _pressed: {
                            opacity: 0.8,
                        }, children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: react_native_1.Platform.OS === 'web' && !isMobileScreen ? 'TEXT' : colors_1.Colors.PRIMARY_300, fontSize: isWebDevice && isMobileScreen ? 16 : undefined, fontWeight: isWebDevice && isMobileScreen ? 600 : undefined, children: react_native_1.Platform.OS === 'web'
                                ? isMobileScreen
                                    ? 'Login'
                                    : 'LOGIN'
                                : 'Login' }) })) : ((0, jsx_runtime_1.jsx)(native_base_1.Pressable, { onPress: () => (0, rootNavigation_1.navigate)("PurchasePlans"), paddingX: 2, paddingY: 1, borderRadius: 15, _pressed: {
                            opacity: 0.8,
                        }, children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.PRIMARY_300, fontSize: 16, fontWeight: 600, children: "Buy" }) })) })] })) }));
};
exports.default = (0, react_1.memo)(AppQuotaAlert);
