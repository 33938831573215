"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = NotesButton;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const AppText_1 = require("../../component/AppText");
const spacing_1 = require("../../common/spacing");
function NotesButton({ fullWidth, navigationButton, showText = true, style, ...restProps }) {
    const [isHovered, setIsHovered] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { onHoverIn: () => setIsHovered(true), onHoverOut: () => setIsHovered(false), style: typeof style === 'function'
            ? (pressed) => [
                styles.button,
                style(pressed),
                fullWidth && { flex: 1 },
                isHovered && styles.buttonHovered
            ]
            : [
                styles.button,
                style,
                fullWidth && { flex: 1 },
                isHovered && styles.buttonHovered
            ], ...restProps, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.iconWrapper, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { alignItems: 'center', justifyContent: 'center' }, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faNoteSticky, color: '#0AEF9D', size: 16 }) }) }), showText && ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: { flex: 1 }, children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.text, children: "Notes" }) })), navigationButton && ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.iconWrapper, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { alignItems: 'center', justifyContent: 'center' }, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faChevronRight, color: '#A0A0A3', size: 16 }) }) }))] }));
}
;
const styles = react_native_1.StyleSheet.create({
    button: {
        backgroundColor: '#25252A',
        gap: 10,
        flexDirection: 'row',
        minWidth: 38,
        alignItems: 'center',
        paddingVertical: spacing_1.Spacing.SPACING_MD,
        paddingHorizontal: spacing_1.Spacing.SPACING_LG,
        borderColor: 'rgba(36, 36, 39, 0.16)',
        borderWidth: 1,
        borderRadius: 38,
    },
    buttonHovered: {
        backgroundColor: '#2F2F35',
    },
    iconWrapper: {
        width: 20,
        height: 20,
        alignContent: 'center',
        justifyContent: 'center',
    },
    text: {
        color: 'white',
        flex: 1,
    },
});
